@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");
.navbar {
  background-image: linear-gradient(-180deg, #005972, #03455f);
  height: 70px;
  display: flex;
  align-items: center;
}

.menu-bars {
  margin-left: 1rem;
  font-size: 2rem;
  background: none;
  padding-top: 15px;
}

.nav-menu {
  text-decoration: none;
  background-image: linear-gradient(-180deg, #005972, #03455f);
  width: 220px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  z-index: 1;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 8px 16px 8px;
  list-style: none;
  height: 60px;
  font-size: xx-large;
}

.nav-text a {
  display: flex;
  justify-content: center;
  align-items: self-end;
}

.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  background-image: linear-gradient(-180deg, #005972, #03455f);
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mybtn {
  position: absolute;
  top: 16px;
  right: 10px;
  font-size: 15px;
  background-color: aqua;
  padding: 10px;
  border-radius: 25px;
  font-weight: bold;
  transition-duration: 0.4s;
  border: 0px;
  margin-top: 1px;
  color: black;
}

.mybtn:hover {
  cursor: pointer;
  background-color: rgb(0, 189, 189);
  color: white;
}

span {
  color: white;
  margin-bottom: 10px;
  height: 10%;
}

.side-text {
  font-family: "Poppins", sans-serif;
}

.side-text:hover {
  color: rgb(0, 238, 238);
  border-radius: 2px;
  text-shadow: 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa,
    0 0 151px #0fa;
}
.side-text-active {
  color: rgb(0, 238, 238);
  border-radius: 2px;
  text-shadow: 0 0 42px #0fa, 0 0 82px #0fa, 0 0 92px #0fa, 0 0 102px #0fa,
    0 0 151px #0fa;
}

span.title {
  font-size: 25px;
  font-style: normal;
  text-align: center;
  justify-content: center;
}
.nav-menu a {
  text-decoration: none;
  align-self: baseline;
  padding: 25px 12px;
}

li.nav-text a > svg {
  height: 50px;
  width: 40px;
  padding-top: 0px;
  padding-bottom: 10px;
}
li.nav-text {
  height: 65px;
}

.gap {
  height: 40px;
}

.menu-close svg {
  box-shadow: none;
  height: 70px;
  width: 70px;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 33px;
}

.foot-items {
  height: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 150px;
}

.foot-items svg {
  border-radius: 15px;
  border-style: solid;
  border-width: 2px;

  padding: 5px 5px;
}

.links svg:hover {
  background-color: rgb(0, 136, 136);
}

.navimg {
  height: 90px;
  width: 240px;
  padding-bottom: 1px;
  object-fit: cover;
  padding-top: 20px;
  padding-right: 80px;
  z-index: 100;
  transition-property: width;
  transition-duration: 1s;
  cursor: pointer;
}

.openicon {
  height: 40px;
  width: 40px;
  cursor: pointer;
  box-shadow: none;
}

.closeicon {
  height: 80px;
  width: 80px;
}
