/* @font-face {
font-family: 'pineapple';
src: url(../assets/fonts/pixel.TTF);
} */

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap");

.hometext {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 30px;
  color: #00313f;
}

@media only screen and (max-width: 1000px) {
  .home {
    display: flex;
    flex-direction: column;
  }
  .hometext {
    font-size: 20px;
    margin-right: 50px;
    padding-top: 50px;
  }

  .mintbtn {
    font-size: 15px;
    padding: -7;
  }

  img.roadmap {
    margin-right: 35px;
    height: 400px;
    width: 400px;
    margin-bottom: 10px;
  }
}

img.roadmap {
  min-height: 90%;
  min-width: 48%;
  margin-top: -50px;
  float: right;
  margin-left: -10px;
}
.home {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 50px;
  overflow: hidden;
}

/* Button element css */

.mintbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.space {
  padding: 20px;
}

.mint_btn {
  color: white;
  font-size: 0.7em;
  border-radius: 5px;
  background-color: #032854;
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-weight: bold;
  transition: background-color 0.4s ease;
  border: none;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 50px;
  display: inline-block;
  max-width: 200px;
}

.mint_btn:hover {
  cursor: pointer;
  background-color: rgb(0, 189, 189);
  color: black;
}
