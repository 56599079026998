*{
    top: 0px;
    right: 0px;
  }
  .modalBackground1 {
      width: 100vw;
      height: 100vh;
      /* background-color: rgba(200, 200, 200); */
      backdrop-filter: blur(8px);
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      
    }
    
    .modalContainer1 {
      width: 500px;
      height: 85%;
      margin-top: 50px;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding: 25px;
    }
    
    .modalContainer1 .title1 {
      display: block;
      text-align: center;
      margin-top: 10px;
    }
    
    .titleCloseBtn1 {
      display: flex;
      justify-content: space-between;
    }
    
    .titleCloseBtn1 button {
      background-color: transparent;
      border: none;
      font-size: 25px;
      cursor: pointer;
    }
    
    .modalContainer1 .body1 {
      flex: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.7rem;
      text-align: center;
     
      margin-top: -5px;
    }
    .body1>img{
      width: 80%;
      height: 80%;
      margin-bottom: 40px;
      object-fit: contain;
      
    }
    
    .snno {
      font-size: 25px;
    }
    .traits1{
      text-align: center;
      font-size: 20px;
      width: 100%;
      height: 100px;
      margin-top: -40px;
    }
    
    .modalContainer1 .footer1 {
      flex: 2%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: -20px;
    }
    
    .modalContainer1 .footer1 button {
      width: 125px;
      height: 45px;
      margin: 10px;
      border: none;
      background-color: #032854;
      color: white;
      border-radius: 80px;
      font-size: 20px;
      cursor: pointer;
    }
    .modalContainer1 .footer1 button:hover {
      background-color: rgb(0, 189, 189);
    }
    
    #cancelBtn1 {
      background-color: crimson;
    }