@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.grid {
  display: grid;
  gap: 20px;
  margin: 0.5rem;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

@media screen and (max-width: 768px) {
  .grid-cols-4 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 500px) {
  .grid-cols-4 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.card {
  background-color: #fff;
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border: #000 solid 1px;
  /* margin: 0.5rem; */
  padding: 0.5rem;
}

.card .image {
  width: 100%;
  height: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.card .image .image-inner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card .text {
  padding: 1rem 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.card .text .owner {
  display: flex;
  gap: 10;
  align-items: center;
}

.owner .owner-image {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 0.5rem;
  border: #000 solid 1px;
}

.owner .owner-image-inner {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.owner .owner-text {
  font-size: larger;
}

.buy-now {
  background-color: #03455f;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.custom-button {
  background-color: #03455f;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

h1.title {
  font-size: 2rem;
  font-weight: 600;
  margin: 0.5rem;
  margin-top: 2rem;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}
